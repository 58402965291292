<template>
  <div class="seat-selection">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="round-info mb-2 d-flex d-md-block text-center">
            <div>
              <div class="info-title">
                {{ $t("round_number") }}
              </div>
              <strong class="round-number">
                {{ round.number }}
              </strong>
            </div>
            <div class="round-description" v-html="round.description"></div>
          </div>
          <!-- /round-info -->
        </div>
        <div class="col-12 col-md-9">
          <div class="seats-table text-center">
            <div class="title">
              {{ $t("select_a_number") }}
            </div>
            <p>{{ $t("get_chance_to_win") }}</p>
            <div class="agenda">
              <div class="agenda__item">
                <span class="circle unavailable"> </span>
                {{ $t("unavailable") }}
              </div>
              <div class="agenda__item">
                <span class="circle available"> </span>
                {{ $t("available") }}
              </div>
              <div class="agenda__item">
                <span class="circle selected"> </span>
                {{ $t("selected") }}
              </div>
              <div class="agenda__item">
                <span class="circle special"> </span>
                {{ $t("special_item") }}
              </div>
            </div>
            <div class="seats-grid d-flex flex-wrap mt-4">
              <div
                v-for="n in round.seats"
                :key="n.number"
                class="circle-holder"
              >
                <div
                  class="circle"
                  :class="{
                    available: !n.reserved && !n.purchased,
                    unavailable: n.reserved || n.purchased,
                    selected: n.number == selected,
                    special: n.number == 0 || n.number == 100,
                  }"
                  @click="selectNumber(n)"
                >
                  {{ n.number }}
                </div>
              </div>
            </div>
            <div class="pt-5">
              <b-button
                @click="selectRandowNumber"
                variant="primary"
                class="random-cta w-100"
                :disabled="randomizing"
              >
                <icon :variant="'dice'" class="me-2" />
                {{ $t("randomly_select_number") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["round"],
  data() {
    return {
      selected: null,
      numbers: [],
      randomizing: false,
    };
  },
  computed: {
    availableSeats() {
      return this.round.seats.filter((n) => !n.reserved && !n.purchased);
    },
  },
  methods: {
    selectRandowNumber() {
      this.randomizing = true;
      let seats = this.availableSeats;
      let random = [];
      for (let i = 0; i < 12; i++) {
        random.push(seats[Math.floor(Math.random() * seats.length)]);
      }
      let delay = 300;
      random.forEach((n) => {
        setTimeout(() => {
          this.selectNumber(n);
        }, delay);
        delay += 100;
      });
      this.randomizing = false;
    },
    selectNumber(n) {
      if (!n.reserved && !n.purchased) {
        if (n.number != 0 && n.number != 100) {
          this.selected = n.number;
        } else {
          this.selected = null;
        }
        this.emitSeletction();
      }
    },

    emitSeletction() {
      this.$emit("select", this.selected);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.seat-selection {
  .random-cta {
    svg {
      height: 32px;
      width: 32px;
    }
    font-size: 1.2rem;
  }
  .circle {
    border-radius: 50%;
    background: #55567d;
    height: 28px;
    width: 28px;
    min-width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e0e0e0;
    font-size: 12px;
    margin: 8px;
    cursor: pointer;
    font-weight: 700;
    &.available {
      background: #2ca6ff;
      color: #000000;
    }
    &.selected {
      background: #ffbd2c !important;
      color: #000000;
    }
    &.special {
      background: #07741f;
      cursor: default;
    }
    &.unavailable {
      background: #55567d !important;
      cursor: default;
    }
  }
  .agenda {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &__item {
      display: flex;
      margin: 5px 10px;
      align-items: center;
      .circle {
        width: 12px;
        height: 12px;
        min-width: 12px;
        margin: 0;
        margin-right: 6px;
        cursor: default;
      }
    }
  }
  .seats-table {
    flex: 1;
    .title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
    }
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;

      color: #ffffff;
    }
  }
  .round-info {
    @media (min-width: 1024px) {
      text-align: left !important;
      border-right: 1px solid rgba(#fff, 0.1);
      padding-right: 30px;
      height: 100%;
    }
  }
  .info-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }
  .round-number {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    padding-bottom: 30px;
    display: block;
  }
  .round-description {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffbd2c;
    margin: 0 auto;
    padding-bottom: 30px;
    display: block;
  }
}
</style>
