<template>
  <div class="product">
    <div class="container">
      <div class="col-12">
        <div class="product__stage-title">
          <span class="number">
            1
          </span>
          {{ $t("product") }}
        </div>
      </div>
      <div class="col-12">
        <div class="product__info">
          <div class="left">
            <div
              class="poster"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/img/diamond.svg') + ')',
              }"
            >
              <div
                class="poster-image"
                v-if="product.image_url"
                :style="{
                  backgroundImage:
                    'url(' +
                    $utils.getLocaleProductImage(product, $i18n.locale) +
                    ')',
                }"
              ></div>
            </div>
          </div>
          <div class="right">
            <h1>
              {{ $utils.getLocaleProductTitle(product, $i18n.locale) }}
            </h1>
            <div class="price">${{ product.price }}</div>
            <div class="reward">
              <span>
                {{ $t("reward") }}
              </span>
              <div class="d-flex amount">
                <img src="@/assets/img/coin.png" alt="" />
                {{ product.coins }}
              </div>
            </div>
            <div
              class="description"
              v-html="$utils.getLocaleProductDescription(product, $i18n.locale)"
            ></div>
          </div>
        </div>
      </div>
      <div class="col-12" id="number-selection">
        <div class="product__stage-title">
          <span class="number">
            2
          </span>
          {{ $t("number_selection") }}
        </div>
        <template v-if="isLoading">
          <div
            class="d-flex w-100 align-items-center justify-content-center"
            style="padding:100px 0px;"
          >
            <b-spinner
              style="width: 3rem; height: 3rem;"
              variant="warning"
            ></b-spinner>
          </div>
        </template>

        <template v-if="round && !isLoading">
          <seatSelection :round="round" @select="selectNumber" />
        </template>
        <template v-if="round == null && !isLoading">
          <div class="no-round-text text-center py-4 my-2">
            <h3 class="py-4" style="font-weight:300;">
              {{ $t("no_active_round_message") }}
            </h3>
            <div class="d-block">
              <b-button href='/shop' variant="primary">
                {{ $t("see_all_offers") }}
              </b-button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="product__buy-floating-button">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex justify-content-end align-items-center">
            <div class="order-info text-right">
              <div class="price">
                {{ $t("price") }}: <span>${{ product.price }}</span>
              </div>
              <div class="selection">
                {{ $t("selected_number") }}:
                <span v-if="selectedNumber">{{ selectedNumber }}</span>
                <span v-else>--</span>
              </div>
            </div>
            <b-button
              variant="primary"
              :disabled="!selectedNumber"
              @click="initOrder"
            >
              {{ $t("buy") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app"
import seatSelection from "@/components/seat-selection.vue"
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      isLoading: false,
      product: {},
      uid: null,
      selectedNumber: null,
      round: false,
    }
  },
  components: {
    seatSelection,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    initOrder() {
      if (this.user) {
        var order = {
          product: this.product,
          round: this.round,
          seat: this.selectedNumber,
        }
        this.$store.dispatch("setOrder", order).then(() => {
          this.$router.push({ name: "checkout" })
        })
      } else {
        this.$store.dispatch("toggleAuthModal", true)
      }
    },
    selectNumber(val) {
      this.selectedNumber = val
      if (val == 0 && val == 100) {
        this.selectedNumber = val
      }
    },
    getProduct() {
      this.isLoading = true
      var uid = this.uid
      var db = firebase.firestore()
      db.collection("products")
        .where("uid", "==", uid)
        .get()
        .then((snapshot) => {
          this.products = {}
          snapshot.forEach((doc) => {
            this.product = doc.data()
          })
          this.getRound()
          this.isLoading = false
        })
        .catch((error) => {
          this.error = error
          console.log(error)
          this.isLoading = false
        })
    },
    getRound() {
      this.isLoading = true
      firebase
        .firestore()
        .collection("rounds")
        .where("linked_product", "==", this.product.uid)
        .get()
        .then((snapshot) => {
          this.round = null
          snapshot.forEach((doc) => {
            this.round = doc.data()
          })
          this.isLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
        })
    },
  },
  mounted() {
    this.uid = this.$route.params.id
    this.getProduct()
  },
}
</script>

<style lang="scss" scoped>
.product {
  padding-bottom: 180px;
  &__buy-floating-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    background: #10112c;
    box-shadow: 0px 0px 18px 0px rgba(#000, 0.6);
    .order-info {
      text-align: right;
      margin-right: 20px;
      color: #6f8290;
      .price {
        span {
          color: #ffbd2c;
          width: 60px;
          display: inline-block;
        }
      }
      .selection {
        span {
          color: #fff;
          width: 60px;
          display: inline-block;
        }
      }
    }
    button {
      font-size: 24px;
      height: 48px;
      line-height: 1;
      min-width: 160px;
    }
  }
  &__info {
    display: flex;
    padding-bottom: 30px;
    .left {
      width: 120px;
      min-width: 120px;
      margin-right: 30px;
      @media (max-width: 600px) {
        width: 96px;
        min-width: 96px;
      }
      .poster {
        padding-top: 140%;
        background: #6b6969;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 30% auto;
        position: relative;

        .poster-image {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-position: center;
          background-size: cover;
        }
      }
    }

    .right {
      h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #ffffff;
        @media (max-width: 600px) {
          font-size: 18px;
          line-height: 1.2;
        }
      }
      .price {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #ffbd2c;
      }
      .reward {
        margin-top: 10px;
        .amount {
          align-items: center;
          color: #fff;
          img {
            height: 16px;
            margin-right: 2px;
            transform: translateX(-4px);
          }
        }
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #888dff;
        }
      }
      .description {
        margin-top: 20px;
        color: #fff;
        @media (max-width: 600px) {
          font-size: 13px;
        }
      }
    }
  }
  &__stage-title {
    display: flex;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #ffffff;
    border-bottom: 1px solid rgba(#fff, 0.3);
    padding-bottom: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
    .number {
      width: 24px;
      height: 24px;
      min-width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #ffbd2c;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      margin-right: 10px;
      color: #000000;
    }
  }
}
</style>
